import Swal from 'sweetalert2';

const propertiesCarousel = {
	slidesPerView: 1,
	spaceBetween: 5,
	navigation: {
		nextEl: ".modal-about-activity__swiper-button .swiper-button-next",
		prevEl: ".modal-about-activity__swiper-button .swiper-button-prev",
	},
};


function calculateFinalValues () {
	let order_config = {
		tour_id: null,
		adults: 0,
		children: 0,
		start_time: null,
		end_time: null,
		activities: []
	};

	var btnAdd = $('#adicionar-passeio');
	
	btnAdd.attr('data-pa', $('#btn-label-adults').val());
	btnAdd.attr('data-ca', $('#btn-label-children').val());
	btnAdd.attr('data-ba', $('#btn-label-baby').val());

	var tourInfo = JSON.parse(btnAdd.attr('data-tour'));
	order_config.tour_id = tourInfo.id;
	order_config.adults = btnAdd.attr('data-pa');
	order_config.children = btnAdd.attr('data-ca');
	order_config.babies = btnAdd.attr('data-ba');
	order_config.start_time = tourInfo.start_time;

	// Popular as atividades
	var activitiesModalList = $('#ac_div');
	$.each(activitiesModalList.find('div.card'), function(item) {
		var li = $(this).parents('div');
		var activityJson = JSON.parse(li.attr('data-activityinfo'));
		var activityState = JSON.parse(li.attr('data-state'));
		if(activityState.included) {
			order_config.activities.push({
				id: activityJson.id,
				adults: activityState.pa,
				children: activityState.pc,
				start_time: tourInfo.start_time,
				end_time: activityJson.end_time
			});
		}
	})

	// Request
	$.ajax({
		url: '/order-calc',
		method: 'POST',
		data: order_config,
		dataType: 'json'
	}).done(function(data){
		console.log('Total da compra - seletor atividades');
		$('#total_adults').text(data.adults.total);
		$('#total_children').text(data.children.total);
		$('#total_activities').text(data.activities.total);
		$('#order_total').text(data.total);
		$('#tour_price_adults').text(data.adults.total);
		$('#tour_price_children').text(data.children.total);

		// Popule as quantidades e valores corretos para o passeio

		// Popule as quantidades e valores corretos para cada atividade
		$.each(data.activities.items, function(index, item){
			var modalActivity = $('.activities-modal[data-id='+ item.id +']');

			modalActivity.find('.activity_price_adults').text(item.adults.total);
			modalActivity.find('.activity_price_children').text(item.children.total);
		});

		$.each(data.activities_available, function(index, item){
			var activityPrice = $('#activity-'+item.id+'-price');
			activityPrice.text(item.price_cents);
		});		
	});
}

const modal = () => {
	const generalModal = document.querySelector("#bookingmodal");
	const cancelButton = document.querySelector("[data-cancel]");
	const backButton = document.querySelector("[data-cancel-modal-about]");
	const cards = document.querySelectorAll(".availability-item");
	const activitiesModal = document.querySelector(".activities-modal");
	const tours = document.querySelectorAll("[data-add]");
	const successModal = document.querySelector(".modal-success");
	const addTourButton = document.querySelector("[data-add-tour]");
	const keepBuying = document.querySelector("[data-buying]");
	const aboutTour = document.querySelectorAll("[data-about]");
	const aboutTourModal = document.querySelector(".modal-about-activity");
	const addActivityTour = document.querySelector("[data-add-activity-tour]");
	const allAcCards = document.querySelectorAll(".modal-accordion-card__item");
	// Concertar cálculo acTotal (somatório act)
	const acTotal = document.getElementById("total_activities");
	// Concertar cálculo totalPrice (somatório tour + Act)
	const totalPrice = document.getElementById("order_total");
	let swiper = null;
	if (generalModal) {
		// Modal Geral
		[].forEach.call(cards, card => {
			card.addEventListener("click", event => {
				event.preventDefault();

				const booking = $('#tour-booking');
				if (booking.is(':hidden')) booking.show();
		
				$([document.documentElement, document.body]).animate({
					scrollTop: booking.offset().top - 60
				}, 300);

				const domElement = $('[data-card='+ card.dataset.card +']');

				const tour = JSON.parse(
					domElement.attr('data-tour')
				);

				const buyersQuantity = document.getElementsByClassName("btn-label");
				const adultsQuantity = parseInt(buyersQuantity[0].value);
				const childrenQuantity = parseInt(buyersQuantity[1].value);
				const babyQuantity = parseInt(buyersQuantity[2].value);
				// Total de assentos no pedido
				let saleSeats = adultsQuantity + childrenQuantity + babyQuantity;
				// Assentos disponíveis
				let availableSeats = parseInt(domElement.attr('data-spots'));
				// Hora em tempo real
				let timeCurrent = new Date();

				let tourStartDate = Date.parse(tour.start_time);
				let tourAvailability = Date.parse(tour.availability);

				// Horas de availability da tour em minutos
				let avHourInMinutes = new Date(tourAvailability).getHours() * 60;
				let avMinutes = new Date(tourAvailability).getMinutes();
				// horas transformadas em minutos + minutos, tranformados em MS
				let totalMinutesMs = (avHourInMinutes + avMinutes) * 60000

				// cálculo dia do passeio de tour - availability de venda da tour
				let limitTime = new Date(tourStartDate - totalMinutesMs);

				// Se hora atual >= hr de disponibilidade de venda
				// ou se qtdade de assentos maior que assentos disponíveis no dia
				// PRECISA TESTAR "availableSeats < saleSeats"
				if (timeCurrent >= limitTime || availableSeats < saleSeats) {
					// Proibe clicar no card indisponível para venda
					generalModal.style.display = "none";
				} else {
					generalModal.style.display = "block";
				}

				const time_selected_text = document.getElementById(
					"modal-accordion__time_selected"
				);
				const start_time_displayed = new Date(tour.start_time);
				time_selected_text.innerText = `Dia ${start_time_displayed.getDate()} às ${domElement.attr('data-start')}`;


				const sessionAdults = document.querySelector(
					".modal-accordion-list-right__adult"
				);
				const adultsSelected = document.querySelector("#btn-label-auldts");
				// sessionAdults.innerText = `R$ ${parseInt(adultsSelected.textContent) *
				// tour.price_cents}`;

				const sessionChildren = document.querySelector(
					".modal-accordion-list-right__child"
				);
				const childrenSelected = document.querySelector("#btn-label-children");

				// Hardcode no desconto para criança
				// sessionChildren.innerText = `R$ ${(parseInt(
				// 	childrenSelected.textContent) *
				// 	tour.price_cents) / 2 }`;

				const totalPriceChildren = document.getElementById("total_children");
				const totalChildrenQuantity = document.getElementById(
					"btn-label-children"
				);
				const totalPriceAdults = document.getElementById("total_adults");
				const totalAdultsQuantity = document.getElementById(
					"btn-label-adults"
				);
				const total_price = document.getElementById("order_total");

				// totalPriceChildren.innerText = `${(
				// 	(parseInt(childrenSelected.value) * tour.price_cents) /
				// 	2
				// )
				// 	.toFixed(2)
				// 	.replace(/\./, ",")}`;
				// totalChildrenQuantity.innerText = `${childrenSelected.value}x`;
				// totalPriceAdults.innerText = `${(
				// 	parseInt(adultsSelected.textContent) * tour.price_cents
				// )
				// 	.toFixed(2)
				// 	.replace(/\./, ",")}`;
				// totalAdultsQuantity.innerText = `${adultsSelected.textContent}x`;
				/*total_price.innerText = `${(
					(parseInt(childrenSelected.textContent) * tour.price_cents) / 2 +
					parseInt(adultsSelected.textContent) * tour.price_cents
				)
					.toFixed(2)
					.replace(/\./, ",")}`;*/
				const spots = parseInt(domElement.attr('data-spots'));
				const spotsDisplay = document.getElementById("modal-accordion__spots");
				spotsDisplay.innerText = `(${spots} lugares restantes)`;
				addTourButton.dataset.tour = JSON.stringify(tour);
				addTourButton.dataset.pa = domElement.attr('data-adults');
				addTourButton.dataset.ca = domElement.attr('data-children');
				addTourButton.dataset.ba = domElement.attr('data-baby');

				let filteredAdd = [...allAcCards].filter(
					card =>
						new Date(card.dataset.stime).getDate() ==
						new Date(tour.start_time).getDate()
				);
				let filteredRemove = [...allAcCards].filter(
					card =>
						new Date(card.dataset.stime).getDate() !=
						new Date(tour.start_time).getDate()
				);
				filteredRemove.forEach(x => x.remove());
				filteredAdd.forEach(x =>
					document
						.getElementById("ac_div")
						.insertBefore(x, document.getElementById("ac_div").firstChild)
				);

				calculateFinalValues();

			});

		});

		// Modal adicionar atividades
		[].forEach.call(tours, tour => {
			const acAdultsPrice = document.querySelector("activity_price_adults");
			let activityCard = document.getElementById(`activity-${tour.dataset.id}`);
			let activity = JSON.parse(activityCard.dataset.activityinfo);
			let state = JSON.parse(activityCard.dataset.state);

//\\\\\\\\\\\\\\\\\\\\\\//////////////////////
			// Preço total lateral direita = acAdultsPrice
			// const sessionAdultsAct = document.querySelector(
			//   ".activity_price_adults"
			// );

			// Valor quantidade centro
			// const sessionAdultsQtAct = document.querySelector(
			//   ".ac_quantity_adults"
			// );

			// VALOR a ser inserido em state
			// const adultsSelectedAct = document.querySelector(".btn-label");
			// sessionAdultsAct.innerText = `R$ ${parseInt(adultsSelectedAct.textContent) *
			//   activity.price_cents}`;

			// const sessionChildrenAct = document.querySelector(
			//   ".activity_price_children"
			// );
			// const childrenSelectedAct = document.querySelector(".btn-label-children");

			// // Hardcode no desconto para criança
			// sessionChildrenAct.innerText = `R$ ${(parseInt(
			//   childrenSelectedAct.textContent) *
			//   activity.price_cents) / 2 }`;

			// const totalPriceChildren = document.getElementById("total_children");
			// const totalChildrenQuantity = document.getElementById(
			//   "total_children_quantity"
			// );
			// const totalPriceAdults = document.getElementById("total_adults");
			// const totalAdultsQuantity = document.getElementById(
			//   "total_adults_quantity"
			// );
///////////////////\\\\\\\\\\\\\\\\\\\\\\\

			tour.addEventListener("click", () => {
				console.log('Abrindo modal para escolha das características da atividade...');

				// Ação do botão de INCLUIR / REMOVER ATIVIDADE
				activityCard = document.getElementById(`activity-${tour.dataset.id}`);
				activity = JSON.parse(activityCard.dataset.activityinfo);

				// MODIFICAR "state" para receber pa ca ba objeto tour
				state = JSON.parse(activityCard.dataset.state);

//\\\\\\\\\\\\\\\\\\///////////////////
				const adultsQuantity = $('#btn-label-adults').val();
				const childrenQuantity = $('#btn-label-children').val();;
				const babyQuantity = $('#btn-label-baby').val();

				if (state.included === false) {
					state.pa = adultsQuantity;
					state.pb = babyQuantity;
					state.pc = childrenQuantity;
					state.included = true;
					activityCard.dataset.state = JSON.stringify(state);
					
					activitiesModal.dataset.id = activity.id;
					// Valor quantidade adultos centro
					document.getElementById(
						"ac_quantity_adults-"+tour.dataset.id+""
					).value = `${adultsQuantity}`;
					// ).innerText = `${state.pa}`;

					// Valor quantidade children centro
					document.getElementById(
						"ac_quantity_children-"+tour.dataset.id+""
					).value = `${childrenQuantity}`;
					// ).innerText = `${state.pc}`;

					// Valor quantidade bebê
					document.getElementById("ac_quantity_baby-"+tour.dataset.id+"").value = `${babyQuantity}`;
					// document.getElementById("ac_quantity_baby").innerText = `${state.pb}`;

					// Preço total lateral direita Adulto
					// acAdultsPrice.innerText = `R$ ${parseInt(
					// 	document.getElementById("ac_quantity_adults").innerText
					// ) * activity.price_cents}`;

					// // Preço total lateral direita Children
					// const acChildrenPrice = document.getElementById(
					// 	"activity_price_children"
					// );
					// // hardcoded desconto children
					// acChildrenPrice.innerText = `R$ ${(parseInt(
					// 	document.getElementById("ac_quantity_children").innerText
					// 	) *
					// 	activity.price_cents) /
					// 2}`;
					// tour.className = "btn btn-warning";
					// tour.innerText = "Editar / remover";
					calculateFinalValues();
				} else {
					// EDITAR / REMOVER
					activityCard = document.getElementById(`activity-${tour.dataset.id}`);
					state = JSON.parse(activityCard.dataset.state);

					document.getElementById(
						"ac_quantity_adults-"+tour.dataset.id+""
					).value = `${state.pa}`;
					// ).innerText = `${state.pa}`;

					// Valor quantidade children centro
					document.getElementById(
						"ac_quantity_children-"+tour.dataset.id+""
					).value = `${state.pc}`;
					// ).innerText = `${state.pc}`;

					// Valor quantidade bebê
					document.getElementById("ac_quantity_baby-"+tour.dataset.id+"").value = `${state.pb}`;					
				

					// hardcoded desconto children
					acTotal.innerText = `${(
						parseFloat(acTotal.innerText.replace(/\,/, ".")) -
						(state.pa * activity.price_cents +
							(state.pc * activity.price_cents) / 2)
					)
						.toFixed(2)
						.replace(/\./, ",")}`;

					// hardcoded desconto children
					/*totalPrice.innerText = `${(
						parseFloat(totalPrice.innerText.replace(/\,/, ".")) -
						(state.pa * activity.price_cents +
							(state.pc * activity.price_cents) / 2)
					)
						.toFixed(2)
						.replace(/\./, ",")}`;*/

				}
				calculateFinalValues();
			});

		});
		let includeFinishBtn = document.querySelector(".activities-modal-footer");

		if (includeFinishBtn) {
			includeFinishBtn.addEventListener("click", (event) => {
				let activityCard = document.getElementById(
					`activity-${event.target.dataset.id}`
				);
				let state = JSON.parse(activityCard.dataset.state);
				let priceAdults = document.querySelector(".activity_price_adults").innerText.replace('R$ ', '');
				let priceChildren = document.querySelector(".activity_price_children").innerText.replace('R$ ', '');
	
				let activityPriceAdults = priceAdults == '0' ? 0 : parseFloat(priceAdults.replace(/\,/, "."));
				let activityPriceChildren = priceChildren == '0' ? 0 : parseFloat(priceChildren.replace(/\,/, "."));
				let totalPriceAdultsChildren = parseFloat(activityPriceAdults + activityPriceChildren);
				let valorAcTotal = acTotal.innerText == '0' ? 0 : parseFloat(acTotal.innerText.replace(/\,/, "."));
				let valorTotalPrice = totalPrice.innerText == '0' ? 0 : parseFloat(totalPrice.innerText.replace(/\,/, "."));
	
				acTotal.innerText = `${(
					parseFloat(valorAcTotal + totalPriceAdultsChildren)
				)
					.toFixed(2)
					.replace(/\./, ",")}`;
	
				/*totalPrice.innerText = `${(
					parseFloat(valorTotalPrice + totalPriceAdultsChildren)
				)
					.toFixed(2)
					.replace(/\./, ",")}`;*/
	
	
				var activityActionBtn = $(activityCard).find('.btn-atividade');
				if(state.pa == 0 && state.pc == 0 && state.pc == 0) {
					state.included = false;				
					// activityActionBtn.removeClass('btn-warning');
					// activityActionBtn.addClass('btn-success');
					// activityActionBtn.text('Incluir');		
				} else {
					state.included = true;				
					// activityActionBtn.removeClass('btn-success');
					// activityActionBtn.addClass('btn-warning');
					// activityActionBtn.text('Editar / remover');	
				}
				activityCard.dataset.state = JSON.stringify(state);			
				calculateFinalValues()
	
			});
		}


		// Modal passeio adicionado
		addTourButton.addEventListener("click", async () => {
			const notificationNumber = $(
				".badge-products"
			);
			let tour = JSON.parse(addTourButton.dataset.tour);

			const allAcCards = document.querySelectorAll(
				".card-atividade"
			);
			const acOrder = [];
			allAcCards.forEach(cardAc => {
				let card = cardAc.parentElement;
				let cardState = JSON.parse(card.dataset.state);
				let cardActivity = JSON.parse(card.dataset.activityinfo);
				if (cardState.included) {
					acOrder.push({
						order: {
							people_amount: cardState.pa,
							children_amount: cardState.pc,
							baby_amount: cardState.pb,
						},
						activity: cardActivity.id,
						start_date: tour.start_time,
						start_time: `${new Date(
							cardActivity.start_time
						).getHours()}:${new Date(cardActivity.start_time).getMinutes()}`,
						type: "activity",
						cart_id: addTourButton.dataset.cart,
					});
				}
			});


			console.log("acOrders: " + acOrder);

			let dataTour = JSON.parse(addTourButton.dataset.tour);
			const orderInfo = {
				order: {
					people_amount: addTourButton.dataset.pa,
					children_amount: addTourButton.dataset.ca,
					baby_amount: addTourButton.dataset.ba,
				},
				start_date: tour.start_time,
				start_time: `${new Date(tour.start_time).getHours()}:${new Date(
					tour.start_time
				).getMinutes().toString().padStart(2, '0')}`,
				type: "tour",
				tour_id: dataTour.id,
				cart_id: addTourButton.dataset.cart,
			};

			try {
				var token = $( 'meta[name="csrf-token"]' ).attr( 'content' );
				console.log('requisitando passeio');
				const response = await fetch(`${window.location.href}/orders/`, {
					method: "POST", // or 'PUT',
					body: JSON.stringify(orderInfo), // data can be `string` or {object}!
					headers: {
						"Content-Type": "application/json",
						"X-CSRF-Token": token,
					},
				});
				const json = await response.json();
				if (json.success != false) {
					notificationNumber.text(parseInt(notificationNumber.text()) + 1);
					$('#cart-count').show();					
				}
				acOrder.forEach(async order => {
					let acResponse = await fetch(`${window.location.href}/orders/`, {
						method: "POST", // or 'PUT'
						body: JSON.stringify(order), // data can be `string` or {object}!
						headers: {
							"Content-Type": "application/json",
							"X-CSRF-Token": token,
						},
					});
					let acJson = await acResponse.json();
					if (acJson.success != false) {
						notificationNumber.text(parseInt(notificationNumber.text()) + 1);
						$('#cart-count').show();
					}
				});
				Swal.fire({
					title: 'Passeio adicionado', 
					icon: 'success',
					html: 'Passeio adicionado com sucesso',
					showCancelButton: true,
					showCloseButton: true,
					confirmButtonText: '<i class="fa fa-cart"></i> Ir para o carrinho',
					cancelButtonText: '<i class="fa fa-cart"></i> Continuar comprando',
				}).then((result) => {
					if(result.isConfirmed) {
						window.location.href="/carts";
					} else {
						window.location.href = document.body.getAttribute("data-search-url");
					}
				});

				// notificationNumber.text(parseInt(json.items));
				// generalModal.style.display = "none";
				// successModal.style.display = "block";
			} catch (error) {
				console.error("Error:", error);
			}
			keepBuying.addEventListener("click", () => {
				successModal.style.display = "none";
				allAcCards.forEach(card => {
					let state = JSON.parse(card.dataset.state);
					let activity = JSON.parse(card.dataset.activityinfo);
					if (state.included) {
						acTotal.innerText = `${(
							parseFloat(acTotal.innerText.replace(/\,/, ".")) -
							(state.pa * activity.price_cents +
								(state.pc * activity.price_cents) / 2)
						)
							.toFixed(2)
							.replace(/\./, ",")}`;

						/*totalPrice.innerText = `${(
							parseFloat(totalPrice.innerText.replace(/\,/, ".")) -
							(state.pa * activity.price_cents +
								(state.pc * activity.price_cents) / 2)
						)
							.toFixed(2)
							.replace(/\./, ",")}`;*/
					}
					state.included = false;
					state.pa = 0;
					state.pc = 0;
					state.pb = 0;
					card.dataset.state = JSON.stringify(state);
				});
				let modalAdd = document.querySelectorAll(
					".modal-accordion-card__add--remove"
				);
				modalAdd.forEach(btn => {
					btn.className = "modal-accordion-card__add";
					// btn.firstElementChild.innerText = "Incluir";
				});
			});
		});

		// Modal Sobre Passeio
		[].forEach.call(aboutTour, tour => {
			tour.addEventListener("click", () => {
				aboutTourModal.style.display = "block";
				const activity = JSON.parse(tour.dataset.about);
				const title = document.querySelector(
					".modal-about-activity-description__title"
				);
				const description = document.querySelector(
					".modal-about-activity-description__overview__text"
				);
				const price = document.querySelector(
					".modal-about-activity-description__price"
				).childNodes[3];
				const included = document.querySelector(
					".modal-about-activity-description__included"
				).childNodes[3];
				const notIncluded = document.querySelector(
					".modal-about-activity-description__not-included"
				).childNodes[3];
				while (included.firstChild) {
					included.removeChild(included.firstChild);
				}
				while (notIncluded.firstChild) {
					notIncluded.removeChild(notIncluded.firstChild);
				}
				const includedItems = activity.includes.split(";");
				const notIncludedItems = activity.includes.split(";");
				const recommendations = document.querySelector(
					".modal-about-activity-recommendations"
				).childNodes[3];
				const photoList = document.querySelector(".swiper-slider-list");
				while (photoList.firstChild) {
					photoList.removeChild(photoList.firstChild);
				}
				if (tour.dataset.photos == "noPhotos") {
					photoList.insertAdjacentHTML(
						"beforeend",
						`<li class="swiper-slide">
					<figure class="modal-about-activity__figure">
                      <img src="https://farm8.static.flickr.com/7472/15636791563_c72e535529_c.jpg" alt="">
                      </figure>
					  </li>`
					);
				} else {
					const photos = JSON.parse(tour.dataset.photos);
					photos.forEach(photo => {
						photoList.insertAdjacentHTML(
							"beforeend",
							`<li class="swiper-slide">
								<figure class="modal-about-activity__figure">
								<img src="${photo.photo.photo}" alt="">
								</figure>
								</li>`
						);
					});
				}

				title.innerText = activity.name;
				description.innerText = activity.description;
				price.innerText = tour.dataset.price;
				includedItems.forEach(item => {
					let liElement = document.createElement("LI");
					let textItem = document.createTextNode(item);
					liElement.appendChild(textItem);
					included.insertBefore(liElement, included.childNodes[0]);
				});
				notIncludedItems.forEach(item => {
					let liElement = document.createElement("LI");
					let textItem = document.createTextNode(item);
					liElement.appendChild(textItem);
					notIncluded.insertBefore(liElement, notIncluded.childNodes[0]);
				});
				recommendations.innerText = activity.recommendation;
				swiper = new Swiper(
					".modal-about-activity__banner",
					propertiesCarousel
				);
			});
		});



		window.onclick = function(event) {
			if (event.target == generalModal) {
				// generalModal.style.display = "none";
				allAcCards.forEach(card => {

					let state = JSON.parse(card.dataset.state);
					let activity = JSON.parse(card.dataset.activityinfo);

					if (state.included) {

						acTotal.innerText = `${(
							parseFloat(acTotal.innerText.replace(/\,/, ".")) -
							(state.pa * activity.price_cents +
								(state.pc * activity.price_cents) / 2)
						)
							.toFixed(2)
							.replace(/\./, ",")}`;

						/*totalPrice.innerText = `${(
							parseFloat(totalPrice.innerText.replace(/\,/, ".")) -
							(state.pa * activity.price_cents +
								(state.pc * activity.price_cents) / 2)
						)
							.toFixed(2)
							.replace(/\./, ",")}`;*/
					}

					state.included = false;
					state.pa = 0;
					state.pc = 0;
					state.pb = 0;
					card.dataset.state = JSON.stringify(state);
				});
				let modalAdd = document.querySelectorAll(
					".modal-accordion-card__add--remove"
				);
				modalAdd.forEach(btn => {
					btn.className = "modal-accordion-card__add";
					// btn.firstElementChild.innerText = "Incluir";
				});
			}
			if (event.target == aboutTourModal) {
				aboutTourModal.style.display = "none";
			}
			if (event.target == activitiesModal) {
				activitiesModal.style.display = "none";
				allAcCards.forEach(card => {
					let state = JSON.parse(card.dataset.state);
					let activity = JSON.parse(card.dataset.activityinfo);
					if (state.included == false) {
						acTotal.innerText = `${(
							parseFloat(acTotal.innerText.replace(/\,/, ".")) -
							(state.pa * activity.price_cents +
								(state.pc * activity.price_cents) / 2)
						)
							.toFixed(2)
							.replace(/\./, ",")}`;

						/*totalPrice.innerText = `${(
							parseFloat(totalPrice.innerText.replace(/\,/, ".")) -
							(state.pa * activity.price_cents +
								(state.pc * activity.price_cents) / 2)
						)
							.toFixed(2)
							.replace(/\./, ",")}`;
						state.included = false;
						state.pa = 0;
						state.pc = 0;
						state.pb = 0;
						card.dataset.state = JSON.stringify(state);
						*/
						let modalAdd = card.querySelectorAll(
							".modal-accordion-card__add--remove"
						);
						modalAdd.forEach(btn => {
							btn.className = "modal-accordion-card__add";
							// btn.firstElementChild.innerText = "Incluir";
						});
					}
				});
			}
		};
		// addActivityTour.addEventListener("click", () => {
		// 	swiper.destroy();
		// });
	}

	// Modal sobre atividade
	var activityAboutLink  = $('.activities-cards ul li, .activityinfo-div');
	var activityAboutModal = $('.modal-about-activity');
	activityAboutLink.on('click', function() {
		var elem = $(this);
		var dataUrl = elem.is('.activityinfo-div') ? $(this).parents('li').attr('data-url') : $(this).attr('data-url') 
 		var url = dataUrl;
		$.ajax({url: url, method: 'GET', cache: false}).done(function(data){
			aboutTourModal.style.display = "block";
			activityAboutModal.html(data);

			const propertiesCarousel = {
				slidesPerView: 1,
				spaceBetween: 5,
				navigation: {
					nextEl: ".modal-about-activity__swiper-button .swiper-button-next",
					prevEl: ".modal-about-activity__swiper-button .swiper-button-prev",
				},
			};
			swiper = new Swiper(
				".modal-about-activity__banner",
				propertiesCarousel
			);			

			const backButton = document.querySelector("[data-cancel-modal-about]");

			backButton.addEventListener("click", () => {
				aboutTourModal.style.display = "none";
				swiper.destroy(true, false);
			});						
		});
	});

};

export default modal;
