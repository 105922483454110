import Swiper from 'swiper';

// Carousel de Tour#Index -> Passeios mais vendidos

const options = {
  allowTouchMove: false,

  direction: 'horizontal',
  slidesPerView: 3,
  observer: true,
  spaceBetween: 10,
  centeredSlidesBounds: true,
  centeredSlide: true,
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-navigation .swiper-button-next',
    prevEl: '.swiper-navigation .swiper-button-prev'
  },
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    480: {
      slidesPerView: 1,
      spaceBetween: 1,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 1,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
    }			
  }
};

const availabilityCarousel = () => new Swiper('.swiper-container--availability-1', options);

export default availabilityCarousel;
