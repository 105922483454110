const accordion = () => {
  const accordions = document.querySelectorAll('.modal-accordion-header');

  [].forEach.call(accordions, function(accordion) {
    accordion.addEventListener('click', function() {
      const panel = this.nextElementSibling;
      const parent = $(this).parent();
      const parentSiblings = parent.siblings().children('.modal-accordion__content');
      
      //parentSiblings.css('display', 'none');
      $(this).siblings('.modal-accordion__content').animate({height: "toggle"});

      console.log('Abrindo seletor de quantidade de pessoas no passseio...');
    });
  });
}

export default accordion;