const countdown = () => {
  let counter = document.querySelector(".card-price__hour");

  if (counter) {
    let dayCounter = document.getElementById("card-price__hour-days");
    let hourCounter = document.getElementById("card-price__hour-hours");
    let minuteCounter = document.getElementById("card-price__hour-minutes");
    // Set the date we're counting down to
    var countDownDate = new Date(counter.dataset.time).getTime();
    // Update the count down every 1 second
    var x = setInterval(function() {
      // Get todays date and time
      var now = Date.now();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      // Display the result in the element
      dayCounter.innerText = `${days}`;
      hourCounter.innerText = `${hours}`;
      minuteCounter.innerText = `${minutes}`;

      // If the count down is finished
      if (distance < 0) {
        counter.innerHTML = "Venda Encerrada";
        clearInterval(x);
      }
    }, 1000);
  }

  //
};

export default countdown;
