import Swiper from 'swiper';

const propertiesCarousel = {
  slidesPerView: 1.3,
  observer: true,
  spaceBetween: 30,
  centeredSlides: true,
  loop: true,
  loopAdditionalSlides: 1,
  navigation: {
    nextEl: '.container-buttons .swiper-button-next',
    prevEl: '.container-buttons .swiper-button-prev'
    //  \/\/\/\/\/\/
    // Inserir AQUI alguma lógica para swiper pela foto?
    // Tour#Show linha 163
  },
  pagination: {
    el: '.container-paginations-bullets .swiper-pagination',
    type: 'bullets',
    clickable: true,
  }
}

const toursCarousel = () => new Swiper('.tour-promotions__banners', propertiesCarousel);

export default toursCarousel;
