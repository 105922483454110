import availabilityCarousel from "./modules/tours/availability-slide";
import toursCarousel from "./modules/tours/index";
import modal from "./modules/tours/modal";
import accordion from "./modules/tours/accordion";
import countdown from "./modules/tours/countdown";
import simplesbar from "simplebar";

document.addEventListener("DOMContentLoaded", () => {
	availabilityCarousel();
	toursCarousel();
	modal();
	accordion();
	countdown();
	//simplesbar();
});
